import React, { useState } from "react";
import "./LoginPage.css"; // Import stylów

function LoginPage({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const versionMajor = 1;
  const versionMinor = 2;
  const versionPatch = 1; // Ten numer będzie zaktualizowany automatycznie przez skrypt
  const generationDate = "7.01.2025"; // Data też będzie automatycznie zaktualizowana
  const originalDate = generationDate; // data w formacie mm/dd/yyyy
  const [day, month, year] = originalDate.split(".");
  const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
    2,
    "0"
  )}`;

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const body = {
        email: email,
        hpasswd: password,
      };

      const response = await fetch(`/api/authorize/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-store",
        body: JSON.stringify(body),
      });

      if (response.status === 404) {
        setErrorMessage("Nieprawidlowy email lub hasło");
        return;
      }
      if (!response.ok) {
        setErrorMessage(
          "Błąd podczas komunikacji z serwerem." + response.status
        );
        return;
      }

      const userDataArray = await response.json();

      const user = userDataArray.find((user) => user.email === email);

      if (!user) {
        setErrorMessage("Nie znaleziono użytkownika o podanym adresie e-mail.");
        return;
      }

      onLogin(user);
    } catch (error) {
      setErrorMessage("Wystąpił błąd podczas logowania.");
      console.error(error);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Zaloguj się</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Hasło:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Zaloguj się</button>
        </form>
      </div>
      <footer className="footer">
        <p>
          Licencja dla Kancelaria Radcy Prawnego Przemysław Budzyński wykonanie
          Digitklan S.Smoliński wersja z dnia : {formattedDate} nr{" "}
          {`${versionMajor}.${versionMinor}.${versionPatch}`}
        </p>
      </footer>
    </div>
  );
}

export default LoginPage;
